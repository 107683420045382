<template>
  <div
    ref="placeholder"
    style="
      --uc-border-radius: 12px;
      --uc-border-color: #EBECEC;
      --uc-border-width: 2px;
      --uc-colors-secondary: #384241;
      --uc-text-color: #384241;
      --uc-acc-heading-font-weight: normal;
      --uc-colors-primary: #384241;
      --uc-user-export-background-color: #fff;
      --uc-user-export-heading-color: #384241;
      --uc-user-export-container-padding: 0;
      --uc-user-export-download-icon-color: #fff;
    "
  />
</template>

<script>
import { createUserCockpit } from '@arla-ecosystem/user-cockpit'
import '@arla-ecosystem/user-cockpit/dist/user-cockpit.css'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  mounted() {
    const app = createUserCockpit({
      ...this.model,
      onApiResponse: (response) => {
        if (response.status === 401) {
          window.location = `/#login?returnUrl=${window.location.pathname}`
        }
      }
    })
    app.mount(this.$refs.placeholder)
    this.$nextTick(() => {
      this.initTracking()
    })
  },
  methods: {
    initTracking() {
      this.$el.addEventListener('click', (e) => {
        const downloadButtonClass = 'uc-export-user-data__button'
        if (e.target.closest(`.${downloadButtonClass}`) || e.composedPath()[1].classList.contains(downloadButtonClass)) {
          GtmHelper.trackDownloadData()
        }
      })
    }
  }
}
</script>
